export default function validateAuth(values) {
	
	let errors = {};
	// if (!values.Email) {
	// 	errors.Email = 'Email is Required ';
	// } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
	// 	errors.Email = 'Invalid email address';
	// }
	// if (!values.Password) {
	// 	errors.Password = 'Password is Required';
	// }
	return errors;
}
